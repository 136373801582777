//ProfileMenu.jsx
//Path: /Users/mackspear/SREG Property Finder/SREGPropertyFinder/src/components/navbar/ProfileMenu.jsx
import React, { Suspense, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import AuthContext from '../../contexts/AuthContext';
import { Dropdown } from 'flowbite-react';
import { FaUserCircle } from '../../assets/svgIcons/svgIcons.js';
import './ProfileMenu.css';

const CgProfile = React.lazy(() => import('../../assets/svgIcons/svgIcons.js').then(module => ({ default: module.CgProfile })));
const GiTreehouse = React.lazy(() => import('../../assets/svgIcons/svgIcons.js').then(module => ({ default: module.GiTreehouse })));
const GiUserSettings = React.lazy(() => import('../../assets/svgIcons/svgIcons.js').then(module => ({ default: module.GiUserSettings })));
const BsFillHouseHeartFill = React.lazy(() => import('../../assets/svgIcons/svgIcons.js').then(module => ({ default: module.BsFillHouseHeartFill })));
const AiOutlineFieldTime = React.lazy(() => import('../../assets/svgIcons/svgIcons.js').then(module => ({ default: module.AiOutlineFieldTime })));
const RiLogoutCircleLine = React.lazy(() => import('../../assets/svgIcons/svgIcons.js').then(module => ({ default: module.RiLogoutCircleLine })));
const IoMdLogIn = React.lazy(() => import('../../assets/svgIcons/svgIcons.js').then(module => ({ default: module.IoMdLogIn })));
const TbHomeRibbon = React.lazy(() => import('../../assets/svgIcons/svgIcons.js').then(module => ({ default: module.TbHomeRibbon })));
const MdSell = React.lazy(() => import('../../assets/svgIcons/svgIcons.js').then(module => ({ default: module.MdSell })));
const BsFillChatDotsFill = React.lazy(() => import('../../assets/svgIcons/svgIcons.js').then(module => ({ default: module.BsFillChatDotsFill })));


const ProfileMenu = ({ setShowProfileMenu, handleLogout, isUserAuthenticated }) => {
    const { user } = useContext(AuthContext);
    const [imageError, setImageError] = useState(false);

    const handleImageError = () => setImageError(true);

    const avatarLabel = !imageError && user && user.photoURL ? (
        <div
            className="profile-image"
            style={{
                backgroundImage: `url(${user.photoURL})`,
                backgroundSize: 'cover',
                borderRadius: '50%',
            }}
        >
            <img
                src={user.photoURL}
                className="profile-image rounded-[50%]"
                alt="User Profile"
                style={{ display: imageError ? 'none' : 'block' }}
                onError={handleImageError}
                aria-label="Profile Image Icon"
            />
        </div>
    ) : (
        <FaUserCircle
            className="w-6 h-6 md:w-7 md:h-7 lg:w-8 lg:h-8 text-purple-600 dark:text-purple-300 hover:text-purple-300 dark:hover:text-purple-400 transition ease-in-out duration-150"
            size={32}
            aria-label="User Profile Icon"
        />
    );

    const handleLogoutClick = () => {
        handleLogout();
    };

    return (
        <div className="relative profile-menu">
            <Dropdown
                className="absolute !-left-10 max-[300px]:!-left-5 !min-w-[15vw] !min-h-[25vh]"
                label={
                    isUserAuthenticated ? avatarLabel : (
                        <FaUserCircle
                            className="w-6 h-6 md:w-7 md:h-7 lg:w-8 lg:h-8 text-purple-600 dark:text-purple-300 hover:text-purple-300 dark:hover:text-purple-400 transition ease-in-out duration-150"
                            size={32}
                            onClick={() => setShowProfileMenu(true)}
                            aria-label="User Profile Icon"
                        />
                    )
                }
                arrowIcon={false}
                inline
                dismissOnClick={false}
                aria-label="Profile Menu"
            >
                <Suspense fallback={
                    <div
                        className="loading-spinner"
                        role="status"
                        aria-label="Loading"
                    ></div>
                }>
                    {isUserAuthenticated ? (
                        <>
                            <Dropdown.Header className="flex flex-col dark-theme-label">
                                {user?.userName ? (
                                    <span className="text-sm md:text-md font-light capitalize" aria-label="User Name">
                                        {user.userName}
                                    </span>
                                ) : (
                                    <Link
                                        to="/dashboard/profilesettings"
                                        className="text-sm md:text-md font-light text-purple-300 hover:text-purple-500 transition ease-in-out duration-150"
                                        aria-label="Choose Your Username"
                                    >
                                        Choose your username
                                    </Link>
                                )}
                                <span className="text-sm md:text-md font-light dark-theme-label" aria-label="User Email">
                                    {user?.email}
                                </span>
                            </Dropdown.Header>

                            {/* Authenticated user menu */}
                            <Dropdown.Item className="font-semibold whitespace-nowrap dark:text-white"  as={Link} to="/featured-listings" aria-label="Featured Listings">
                                <TbHomeRibbon className="w-5 h-5 text-purple-700/80 dark:text-purple-300 hover:text-purple-500/80 dark:hover:text-purple-400 mr-2 flex flex-nowrap" />
                                Featured Listings
                            </Dropdown.Item>
                            <Dropdown.Item className="font-semibold whitespace-nowrap dark:text-white"  as={Link} to="/dashboard/profilesettings" aria-label="Account Settings">
                                <GiUserSettings className="w-5 h-5 text-purple-700/80 dark:text-purple-300 hover:text-purple-500/80 dark:hover:text-purple-400 mr-2 flex flex-nowrap" />
                                Account Settings
                            </Dropdown.Item>
                            <Dropdown.Item className="font-semibold whitespace-nowrap dark:text-white"  as={Link} to="/dashboard/SavedHomes" aria-label="Saved Homes">
                                <BsFillHouseHeartFill className="w-5 h-5 text-purple-700/80 dark:text-purple-300 hover:text-purple-500/80 dark:hover:text-purple-400 mr-2 flex flex-nowrap" />
                                Saved Homes
                            </Dropdown.Item>
                            <Dropdown.Item className="font-semibold whitespace-nowrap dark:text-white"  as={Link} to="/dashboard/RecentlyViewed" aria-label="Recents">
                                <AiOutlineFieldTime className="w-5 h-5 text-purple-700/80 dark:text-purple-300 hover:text-purple-500/80 dark:hover:text-purple-400 mr-2 flex flex-nowrap" />
                                Recents
                            </Dropdown.Item>
                            <Dropdown.Item className="font-semibold whitespace-nowrap dark:text-white"  as={Link} to="/contact" aria-label="Contact Us">
                                <BsFillChatDotsFill className="w-5 h-5 text-purple-700/80 dark:text-purple-300 hover:text-purple-500/80 dark:hover:text-purple-400 mr-2 flex flex-nowrap" />
                                Contact Us
                            </Dropdown.Item>
                            <Dropdown.Item className="font-semibold whitespace-nowrap dark:text-white"  as="button" onClick={handleLogoutClick} aria-label="Log Out">
                                <RiLogoutCircleLine className="w-5 h-5 text-purple-700/80 dark:text-purple-300 hover:text-purple-500/80 dark:hover:text-purple-400 mr-2 flex flex-nowrap" />
                                Log Out
                            </Dropdown.Item>
                        </>
                    ) : (
                        <>
                            {/* Unauthenticated user menu */}
                            <Dropdown.Item className="font-semibold whitespace-nowrap dark:text-white"  as={Link} to="?auth=login" aria-label="Login">
                                <IoMdLogIn className="w-5 h-5 text-purple-700/80 dark:text-purple-300 hover:text-purple-500/80 dark:hover:text-purple-400 mr-2 flex flex-nowrap" />
                                Login
                            </Dropdown.Item>
                            <Dropdown.Item className="font-semibold whitespace-nowrap dark:text-white"  as={Link} to="/featured-listings" aria-label="Featured Listings">
                                <TbHomeRibbon className="w-5 h-5 text-purple-700/80 dark:text-purple-300 hover:text-purple-500/80 dark:hover:text-purple-400 mr-2 flex flex-nowrap" />
                                Featured Listings
                            </Dropdown.Item>
                            <Dropdown.Item className="font-semibold whitespace-nowrap dark:text-white"  as={Link} to="?auth=login" aria-label="Account Settings">
                                <GiUserSettings className="w-5 h-5 text-purple-700/80 dark:text-purple-300 hover:text-purple-500/80 dark:hover:text-purple-400 mr-2 flex flex-nowrap" />
                                Account Settings
                            </Dropdown.Item>
                            <Dropdown.Item className="font-semibold whitespace-nowrap dark:text-white"  as={Link} to="?auth=login" aria-label="Saved Homes">
                                <BsFillHouseHeartFill className="w-5 h-5 text-purple-700/80 dark:text-purple-300 hover:text-purple-500/80 dark:hover:text-purple-400 mr-2 flex flex-nowrap" />
                                Saved Homes
                            </Dropdown.Item>
                            <Dropdown.Item className="font-semibold whitespace-nowrap dark:text-white"  as={Link} to="?auth=login" aria-label="Recents">
                                <AiOutlineFieldTime className="w-5 h-5 text-purple-700/80 dark:text-purple-300 hover:text-purple-500/80 dark:hover:text-purple-400 mr-2 flex flex-nowrap" />
                                Recents
                            </Dropdown.Item>
                            <Dropdown.Item className="font-semibold whitespace-nowrap dark:text-white"  as={Link} to="/buyer-resources" aria-label="Buyer Resources">
                                <GiTreehouse className="w-5 h-5 text-purple-700/80 dark:text-purple-300 hover:text-purple-500/80 dark:hover:text-purple-400 mr-2 flex flex-nowrap" />
                                Buyer Resources
                            </Dropdown.Item>
                            <Dropdown.Item className="font-semibold whitespace-nowrap dark:text-white"  as={Link} to="/seller-resources" aria-label="Seller Resources">
                                <MdSell className="w-5 h-5 text-purple-700/80 dark:text-purple-300 hover:text-purple-500/80 dark:hover:text-purple-400 mr-2 flex flex-nowrap" />
                                Seller Resources
                            </Dropdown.Item>
                            <Dropdown.Item className="font-semibold whitespace-nowrap dark:text-white"  as={Link} to="/contact" aria-label="Contact Us">
                                <BsFillChatDotsFill className="w-5 h-5 text-purple-700/80 dark:text-purple-300 hover:text-purple-500/80 dark:hover:text-purple-400 mr-2 flex flex-nowrap" />
                                Contact Us
                            </Dropdown.Item>
                        </>
                    )}
                </Suspense>
            </Dropdown>
        </div>
    );
};

export default ProfileMenu;
